import { FetchResponse } from '../models/fetch-response-model';
import { http } from './axios';

export class UserService {
	public static getUserPerSite(siteId: number): Promise<FetchResponse> {
		return http.get(`/user-manager/?site=${siteId}`);
	}

	public static getUserTypes(): Promise<FetchResponse> {
		return http.get(`/users/types`);
	}

	public static save(data: any) {
		if (data?.id) {
			return http.put(`/users/${data.id}`, data);
		}
		return http.post("/users", data);
	}
}
